
import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';

import Multiselect from '@vueform/multiselect';

export type Option = {
  value: number | string;
  label?: string;
  translationKey?: string;
  readonly?: boolean;
};

export default defineComponent({
  components: { Multiselect },
  props: {
    modelValue: {
      type: Array as PropType<Array<string | number>>,
      default: () => [],
    },
    errors: {
      type: Array as PropType<any[]>,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<Option[]>,
      default: () => [],
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    bulletList: {
      type: Boolean,
      default: false,
    },
    tagColor: {
      type: String,
      default: 'secondary',
    },
  },
  emits: ['update:modelValue', 'change'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const colorClass = computed(() => `tag-${props.tagColor}`);

    const filteredErrors = computed(() => props.errors.filter((error) => error));

    const mappedOptions = computed(() => {
      // when in bullet list mode, all values are options (to make sure the values are still there after refresh)
      if (props.bulletList) {
        return (props.modelValue ?? []).map((value) => ({ value, label: value }));
      }

      // by default, the options are used
      return props.options.map((option) => ({
        value: option.value,
        label: option.translationKey ? t(option.translationKey) : option.label ?? option.value,
        disabled: option.readonly ?? false,
      }));
    });

    const handleSelect = (value: any) => {
      emit('update:modelValue', [...props.modelValue, value]);
      emit('change', [...props.modelValue, value]);
    };
    const handleDeselect = (value: any) => {
      const newValue = props.modelValue.filter((val) => val !== value);
      emit('update:modelValue', newValue);
      emit('change', newValue);
    };

    return {
      colorClass,
      filteredErrors,
      mappedOptions,

      handleSelect,
      handleDeselect,
    };
  },
});
