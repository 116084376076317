export class UpdateColorCategoryDto {
  name: string;
  innerPrice: number;
  outerPrice: number;
  innerBorderPrice: number;
  doorPrice: number;

  constructor(dto: UpdateColorCategoryDto) {
    this.name = dto.name;
    this.innerPrice = dto.innerPrice;
    this.outerPrice = dto.outerPrice;
    this.innerBorderPrice = dto.innerBorderPrice;
    this.doorPrice = dto.doorPrice;
  }
}
