export class ColorCategoryDto {
  id: number;
  name: string;
  innerPrice: number;
  outerPrice: number;
  innerBorderPrice: number;
  doorPrice: number;

  constructor(dto: ColorCategoryDto) {
    Object.assign(this, dto);
  }
}
