export class ClosetSettingsDto {
  maxAmountOfSamples: number;
  singleMinWidth: number;
  singleMaxWidth: number;
  singleDefaultWidth: number;
  singleMaxHeight: number;
  singleMinHeight: number;
  singleDefaultHeight: number;
  singleMinDepth: number;
  singleMaxDepth: number;
  singleDefaultDepth: number;
  doubleMinWidth: number;
  doubleMaxWidth: number;
  doubleDefaultWidth: number;
  doubleMinHeight: number;
  doubleMaxHeight: number;
  doubleDefaultHeight: number;
  doubleMinDepth: number;
  doubleMaxDepth: number;
  doubleDefaultDepth: number;
  defaultExtraPrice: number;
  customizationOptions: string[];
  plinthoogteOptions: string[];

  constructor(dto: ClosetSettingsDto) {
    Object.assign(this, dto);
  }
}
